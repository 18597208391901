.cta-before-footer {
  background-color: #f8f9fa;
}

.elcerro-button-inverted {
  background-color: rgb(35, 43, 69)!important;
  color: white!important;
  font-family: 'PT Serif', serif!important;
}

.elcerro-button-inverted:hover {
  background-color: white!important;
  color: rgb(35, 43, 69)!important;
  border: 1px solid rgb(35, 43, 69)!important;
}