.cover-jumbotron {
  margin-top: 101px;
  height: 100%;
}

.circle-image {
  border-radius: 5%;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
