.footer {
  margin-top: 2rem;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
