@import "./fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
button {
  font-family: "PT Serif", serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.trajan-pro {
  font-family: "TrajanPro-3", sans-serif;
}

.libre-bakersfield {
  font-family: "Libre Baskerville", serif;
}

.section-end {
  background-color: rgb(237, 233, 233);
  height: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.margin-b-100 {
  margin-bottom: 100px;
}

.nav-link {
  font-size: 1rem !important;
  font-family: "PT Serif", serif;
}

.elcerro-shadow {
  -webkit-box-shadow: 0px 23px 25px 0px rgba(191, 191, 191, 1);
  -moz-box-shadow: 0px 23px 25px 0px rgba(191, 191, 191, 1);
  box-shadow: 0px 23px 25px 0px rgba(191, 191, 191, 1);
}
