.cta-container {
  background-color: rgb(35, 43, 69);
  color: #fff;
}

.elcerro-button {
  background-color: white!important;
  color: rgb(35, 43, 69)!important;
  font-family: 'PT Serif', serif!important;
}

.elcerro-button:hover {
  background-color: rgb(35, 43, 69)!important;
  color: white!important;
  border: 1px solid #fff!important;
}